import React from "react";

import { TooltipInfo, PureCarousel, SEO } from "../../../../components";
import InfoSection from "./sub/InfoSection/InfoSection";
import ActionSection from "./sub/ActionSection/ActionSection";
import { ImgShowroomPlaceholder } from "../../../../../static";
import useLocalization from "../../../../hooks/useLocalization";

import { ShowroomProps } from "../ShowroomCard/ShowroomCard";

interface ShowroomDetailsProps {
  showroom: ShowroomProps;
}

const ShowroomDetails = ({ showroom }: ShowroomDetailsProps) => {
  const { translations } = useLocalization();
  const isRetail = showroom.showroom_type === "retail";
  const tooltipText = isRetail
    ? "Experience Outer products at pop-ups, retailers, and partner stores so you can see, touch, and learn about Outer products before purchasing. Hours and products vary by location; check each showroom listing for more details."
    : "Experience Outer products in the outdoor spaces of real customers so you can see, touch, and learn about Outer products before purchasing. Visit in-person, or connect virtually, to build confidence in your investment before you buy. Connections are appointment based and products vary by location; check each showroom listing for more details.";

  let image = (showroom.photo_details || [{ url: "" }])[0]?.url;

  return (
    <div key={`showroom${showroom.id}`}>
      <SEO
        title={showroom.title}
        image={image}
        branding="Outer Neighborhood Showroom®"
        description={`Outer Furniture Showroom in ${showroom.city}, ${showroom.state} | ${showroom.description}`}
      />
      <div className="component-default-padding">
        <div className="text-center">
          <div className="mb-3 flex items-center justify-center text-sm leading-normal text-secondary-color md:mb-5 md:text-lg">
            {isRetail
              ? "Retail Showroom"
              : translations["ShowroomDetails--tooltip-condition"]}

            <span className="hidden md:block">
              <TooltipInfo placement="right" tooltipText={tooltipText} />
            </span>
            <span className="block md:hidden">
              <TooltipInfo placement="bottom" tooltipText={tooltipText} />
            </span>
          </div>
          <h1 className="h1-styles mb-8 md:mb-20 lg:mb-24">{showroom.title}</h1>
        </div>
        <PureCarousel
          className="mb-24"
          images={showroom.photo_details}
          placeholder={ImgShowroomPlaceholder}
          showArrows
        />
        {showroom.host && (
          <div className="mx-auto grid grid-cols-12 justify-between gap-4 ">
            <ActionSection
              showroom={showroom}
              isRetail={isRetail}
              tooltipText={tooltipText}
            />
            <div style={{ gridColumn: "span 1" }}></div>
            <InfoSection showroom={showroom} isRetail={isRetail} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowroomDetails;
